import React from "react";
import Footer from "src/components/Footer_it";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/it_IT/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/it/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/it/prodotti/" target="_self">
                      Prodotti
                    </a>
                    <ul>
                      <li>
                        <a href="/it/prodotti/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/moisturising/">Moisturising</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/instantglow/">Instant Glow</a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/it/our-heritage/" target="_self">
                      Storia
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/it/know_the_sun/" target="_self">
                      Conosci il sole
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/it/Ma-Dove-Sei-Stata/" target="_self">
                      Ma Dove Sei Stata 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Paesi{" "}
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/wetskin/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/wetskin/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos">PT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/wetskin/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2017-pizbuin_1680x405px_WetSkin.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/wet_skin_960x405_topSmall.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN WET SKIN<sup>®</sup>
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Protezione per la pelle bagnata e asciutta</h3>
                      <p>
                        Quest’estate, quando sarai sotto il sole in riva al
                        mare, non dovrai più asciugarti per ripristinare
                        immediatamente la protezione perduta. Ti basterà
                        applicare PIZ BUIN WET SKIN<sup>®</sup> Spray Solare
                        Trasparente direttamente sulla pelle bagnata, appena
                        esci dall’acqua. Sdraiati, rilassati e lascia che il
                        calore del sole ti asciughi. Grazie all’esclusiva
                        Tecnologia Aquaflex<sup>™</sup>, i nostri Spray Solari
                        Trasparenti Wet Skin passano attraverso l’acqua e si
                        assorbono direttamente sulla pelle bagnata o asciutta.
                        L’efficace protezione UVA / UVB e la tecnologia Wet Skin
                        : senza bisogno di asciugarsi e senza macchie bianche.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="spray-solare-trasparente" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/bdcd8ba994d3fed82688dd3afcaf5ce4_f34.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>SPRAY SOLARE TRASPARENTE</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>Benefici</h4>
                        <p>
                          NUOVO PIZ BUIN WET SKIN<sup>®</sup>, la prima linea di
                          solari PIZ BUIN<sup>®</sup> pensata per essere
                          applicata direttamente sulla pelle bagnata.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            Quando sono applicati sulla pelle bagnata, i normali
                            spray trasparenti possono sbiancarsi e scivolare
                            via, compromettendo in questo modo il livello di
                            protezione.
                          </p>
                          <div
                            style={{
                              clear: "both"
                            }}
                          >
                            <div
                              style={{
                                float: "left",
                                "margin-right": "20px"
                              }}
                            >
                              <img
                                src="/assets/images/wetskin_spray1.jpeg"
                                alt="wetskin_spray.jpg"
                                width="197"
                                height="139"
                              />
                              <p
                                style={{
                                  padding: "5px 20px 5px 0px",
                                  margin: "0px",
                                  "font-size": "0.7em"
                                }}
                              >
                                Normale spray solare trasparente
                              </p>
                            </div>
                            <div
                              style={{
                                float: "left"
                              }}
                            >
                              <img
                                src="/assets/images/wetskin_spray2.jpeg"
                                alt="wetskin_spray.jpg"
                                width="197"
                                height="139"
                              />
                              <p
                                style={{
                                  padding: "5px 20px 5px 0px",
                                  margin: "0px",
                                  "font-size": "0.7em"
                                }}
                              >
                                NUOVO PIZ BUIN WET SKIN<sup>®</sup>
                                <br />
                                Spray Solare Trasparente
                              </p>
                            </div>
                            <div
                              style={{
                                clear: "both"
                              }}
                            >
                              {" "}
                            </div>
                          </div>
                          <p>
                            Mentre i normali schermi solari trasparenti possono
                            compromettere la protezione mischiandosi con l‘acqua
                            al momento dell‘applicazione sulla pelle bagnata,
                            PIZ BUIN WET SKIN<sup>®</sup> Spray Solare
                            Trasparente è diverso perchè passa direttamente
                            attraverso l‘acqua e viene assorbito istantaneamente
                            dalla pelle.
                          </p>
                          <p>
                            La formula idrata la pelle per garantire che non si
                            secchi mentre ti godi il sole.
                          </p>
                          <p>
                            Il NUOVO PIZ BUIN WET SKIN<sup>®</sup> Spray Solare
                            Trasparente non è grasso, è facile da applicare ed è
                            doppiamente resistente all'acqua.
                          </p>
                          <p>
                            Il NUOVO PIZ BUIN WET SKIN<sup>®</sup> Spray Solare
                            Trasparente può essere applicato sia sulla pelle
                            bagnata che asciutta.
                          </p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Leggi di piu´
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>SPF disponibili</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>Modo d’uso</h4>
                        <p>
                          Applicare abbondantemente e uniformemente prima di
                          esporsi al sole. Diminuendo la quantità il livello di
                          protezione si riduce sensibilmente. Riapplicare
                          soprattutto dopo aver sudato, nuotato o essersi
                          asciugati. Evitare l'esposizione al sole nelle ore
                          centrali del giorno. Tenere neonati e bambini al
                          riparo dalla luce diretta del sole. Non spruzzare
                          direttamente sul viso. Per l'uso sul viso, spruzzare
                          sulle mani e applicare evitando il contatto con gli
                          occhi. INFIAMMABILE. Tenere lontano dalla portata dei
                          bambini e dalle fonti d’ignizione. Non spruzzare su
                          fiamme libere o su materiale incandescente.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTI E TECNOLOGIE{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Sistema di filtri solari avanzati UVA/UVB di PIZ
                                BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Come specialisti di prodotti solari, usiamo
                                    tecnologie innovative per garantire il
                                    giusto equilibrio tra abbronzatura e
                                    protezione tutte le volte che ti esponi al
                                    sole.
                                  </p>
                                  <p>
                                    Ogni sistema di filtri solari avanzati
                                    UVA/UVB di PIZ BUIN<sup>®</sup> è una
                                    tecnologia all’avanguardia sviluppata da
                                    Kenvue per la protezione dal
                                    sole. Questo sistema offre una protezione
                                    fotostabile ad ampio spettro dai raggi UVA e
                                    UVB, pertanto le protezioni solari Piz Buin
                                    osservano le ultime direttive Europee.
                                  </p>
                                  <p>
                                    Raggi UVB - "i raggi che scottano" -
                                    danneggiano la superficie cutanea e sono la
                                    principale causa di scottature.
                                  </p>
                                  <p>
                                    Raggi UVA - "i raggi che invecchiano" -
                                    penetrano più a fondo e danneggiano
                                    collagene ed elastina, il che può provocare
                                    l'invecchiamento precoce della pelle e il
                                    cancro.
                                  </p>
                                  <p>
                                    I sistemi avanzati di filtri solari UVA/UVB
                                    di PIZ BUIN<sup>®</sup> contengono sia
                                    filtri UVA che UVB per proteggerti dal sole
                                    e dai danni a lungo termine, ma anche dagli
                                    effetti più gravi provocati dai raggi UVA.
                                    Coprono gran parte dello spettro UVA/UVB,
                                    offrendo un'ampia gamma di protezione per la
                                    tua pelle.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Vitamina E
                                <div className="textBlock bottom">
                                  <p>
                                    La vitamina E è un potente antiossidante
                                    naturale. Aiuta la protezione dai radicali
                                    liberi che danneggiano le cellule cutanee e
                                    causano l'invecchiamento precoce della
                                    pelle.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Tecnologia Wet Skin
                                <div className="textBlock bottom">
                                  <p>
                                    Wet Skin è una tecnologia innovativa che
                                    consente di applicare la protezione solare
                                    direttamente sulla pelle bagnata senza
                                    compromettere il livello di protezione.
                                  </p>
                                  <p>Che cosa fa la tecnologia Wet Skin?</p>
                                  <p>
                                    <em>
                                      <strong>Passa attraverso l'acqua:</strong>
                                    </em>
                                  </p>
                                  <p>
                                    Confrontato con normali schermi solari
                                    trasparenti PIZ BUIN WET SKIN<sup>®</sup>{" "}
                                    Spray Solare Trasparente non si combina con
                                    l'acqua sulla pelle ma passa direttamente
                                    attraverso di essa. Grazie al rapporto
                                    "aureo" tra i filtri UV, l'olio e l'alcol,
                                    lo spray forma una separazione tra la
                                    formula e le gocce d'acqua.
                                  </p>
                                  <p>
                                    <em>
                                      <strong>
                                        Migliore applicazione e adesione sulla
                                        pelle bagnata:
                                      </strong>
                                    </em>
                                  </p>
                                  <p>
                                    Una combinazione di poliestere, resina e
                                    silicone impedisce l’esfoliazione sulla
                                    pelle bagnata e migliora l'applicazione.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/cfd1bbda111e0a24f5706db2e4475dc7_f50.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/it/prodotti/hydro-infusion/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  {/* <li>
                    <a href="/it/prodotti/active-protect/" target="_self">
                      Active &amp; Protect
                    </a>
                  </li> */}
                  <li>
                    <a href="/it/prodotti/moisturising/" target="_self">
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  {/* <li>
                    <a href="/it/prodotti/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li> */}
                  <li>
                    <a href="/it/prodotti/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/it/prodotti/wetskin/"
                      target="_self"
                    >
                      Wet Skin
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/aftersun/" target="_self">
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/gallery/" target="_self">
                      Gallery
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinItalia?fref=ts"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuin_italia/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// Conversion Name: LP/HP Piz buin\n// INSTRUCTIONS \n// The Conversion Tags should be placed at the top of the <BODY> section of the HTML page. \n// In case you want to ensure that the full page loads as a prerequisite for a conversion \n// being recorded, place the tag at the bottom of the page. Note, however, that this may \n// skew the data in the case of slow-loading pages and in general not recommended. \n//\n// NOTE: It is possible to test if the tags are working correctly before campaign launch \n// as follows:  Browse to http://bs.serving-sys.com/Serving/adServer.bs?cn=at, which is \n// a page that lets you set your local machine to 'testing' mode.  In this mode, when \n// visiting a page that includes an conversion tag, a new window will open, showing you \n// the data sent by the conversion tag to the MediaMind servers. \n// \n// END of instructions (These instruction lines can be deleted from the actual HTML)\nvar ebRand = Math.random()+'';\nebRand = ebRand * 1000000;\ndocument.write('<scr'+'ipt src=\"HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;rnd=' + ebRand + '\"></scr' + 'ipt>');\n"
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <img width="1" height="1" style="border:0" src="HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;ns=1">\n      '
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Leggi di piu´');\n} else {\nbox.slideDown();\n$(this).text('Leggi meno');\n}\n});\n});\n"
          }}
        />
        
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
