import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer id="footer">
        <div className="insideFooter">
        <ul className="nav">
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow">Cookie Policy</a>
                </li>
                  <li>
                    <a href="/it/legal/contact-us/" target="_self">
                      Contatti
                    </a>
                  </li>
                  <li className="nav-selected nav-path-selected">
                    <a
                      href="/it/legal/legal-notice/"
                      target="_self"
                      className="nav-selected nav-path-selected"
                    >
                      Note legali
                    </a>
                  </li>
                  <li>
                    <a href="/it/legal/private-policy/" target="_self">
                      Privacy policy
                    </a>
                  </li>
                  <li>
                    <a href="/it/legal/cookie-policy/" target="_self">
                      Cookie policy
                    </a>
                  </li>
                </ul>
                <div className="clear" />
                <p
                  style={{
                    float: "none"
                  }}
                >
                  Questo sito è pubblicato da Johnson &amp; Johnson S.p.A.,
                  unico responsabile
                  <br />
                  dei contenuti, ed è destinato ai visitatori residenti in
                  Italia. Accedendo a<br />
                  questo sito, l'utente è tenuto a prendere conoscenza e ad
                  accettare le nostre
                  <br />
                  Condizioni generali, la nostra Privacy Policy e la nostra
                  Cookie Policy.
                </p>
                <p>
                  <br />© Johnson &amp; Johnson S.p.A Via Ardeatina Km 23,500 –
                  00071. <br />
                  S. Palomba – Pomezia (RM),C.F. 00407560580 – P.I. 00884611005{" "}
                  <br />
                  Iscritta presso tribunale di Roma Registro delle Imprese al n.
                  353/ 67
                  <br />
                  Capitale Sociale €uro 5.170.000 Interamente Versato.
                  <br />
                  Ultimo aggiornamento 31/08/2021
                </p>
              </div>
              </footer>
    );
  }
}

export default Footer;
